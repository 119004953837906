import PropTypes from 'prop-types';
import { FormLabel, Grid, useColorModeValue } from '@chakra-ui/react';
import { isRealString } from '@/utils/helpers.js';

const FormRow = ({ id, label, children }) => {
  const labelColor = useColorModeValue('gray.500', 'gray.400');
  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: '1fr 4fr',
      }}
      gap={4}
      alignItems="center"
      width={{
        base: '100%',
        md: '90%',
      }}
    >
      <FormLabel
        htmlFor={id ?? ''}
        mb="0"
        textAlign={{
          base: 'start',
          md: 'end',
        }}
        color={labelColor}
      >
        {isRealString(label) ? label : ''}
      </FormLabel>
      {children}
    </Grid>
  );
};

FormRow.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
};

export default FormRow;
