import React, { useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useSelector } from 'react-redux';
import AlertComponent from '../common/AlertComponent';
import { selectSalesReps } from '../user/userSlice';
import { selectContractDivisionsList, selectContractFacilities } from '../contract/contractSlice';
import { BsPersonPlus } from 'react-icons/bs';
import { getAllDisabledFacilitiesByDivision, getAllStatesAttachedToContract } from '../contract/contractActions';
import { chakraStyles } from '../common/select/styles';
import { SingleValueFirstLastName } from '../common/select/SingleValue';
import { OptionFirstLastName } from '../common/select/Option';
import { ClearIndicator } from '../common/select/ClearIndicator';
import { useGetContractRepsQuery } from '@/app/services/nucleus.js';
import { debounceSearch } from '@/features/contract/contractHelper.js';
import FacilityTransferList from '@/features/salesRep/FacilityTransferList.jsx';
import { useTransferList } from '@/hooks/useTransferList.js';
import { isArrayValue } from '@/utils/helpers.js';

const COLUMNS = ['name', 'address'];

const AddSalesRep = ({ id, isOpen, onClose, onSaveHandler, isLoading, isError, errorMessage, reset }) => {
  const initialRef = useRef(null);
  const divisionRef = useRef(null);
  const timerRef = useRef(null);

  const allSalesReps = useSelector(selectSalesReps);
  const facilities = useSelector(selectContractFacilities);
  const divisionsList = useSelector(selectContractDivisionsList);
  const { data: salesReps } = useGetContractRepsQuery({ id });

  const [rep, setRep] = useState(null);
  const [facility, setFacility] = useState([]);
  const [divisions, setDivisions] = useState(null);
  const [divisionNames, setDivisionNames] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [showError, setShowError] = useState(false);
  const [displayDisabledFacilities, setDisplayDisabledFacilities] = useState(false);
  const [transferRight, setTransferRight] = useState([]);
  const [transferLeft, setTransferLeft] = useState([]);
  const [stateFilter, setStateFilter] = useState(null);
  const [facilityNameFilter, setFacilityNameFilter] = useState('');
  const [facilityFilterStatus, setFacilityFilterStatus] = useState('');
  const [facilitiesByName, setFacilitiesByName] = useState([]);
  const [noResult, setNoResult] = useState(false);

  const enabledSalesReps = useMemo(() => allSalesReps.filter((u) => !u?.deleted_at), [allSalesReps]);
  const disabledFacilityIds = useMemo(() => getAllDisabledFacilitiesByDivision(salesReps, facilities, divisions), [salesReps, divisions]);
  const statesAttachedToContract = useMemo(
    () => getAllStatesAttachedToContract(facilities, disabledFacilityIds, displayDisabledFacilities),
    [facilities, disabledFacilityIds, displayDisabledFacilities],
  );

  // INFO: this will make sure values get reset when the component is re-mounted
  useEffect(() => {
    setRep(null);
  }, []);

  useTransferList(
    facility,
    setNoResult,
    facilityNameFilter,
    displayDisabledFacilities,
    disabledFacilityIds,
    selectedFacilities,
    setSelectedFacilities,
    transferRight,
    stateFilter,
    facilitiesByName,
    setTransferLeft,
  );

  const repDivisions = useMemo(() => {
    if (rep?.id && isArrayValue(salesReps)) {
      const assoc = salesReps.filter((u) => u.rep_id === rep.id);
      return assoc ? [...new Set(assoc.reduce((a, div) => [...a, ...div.divisions], []))] : [];
    } else {
      return [];
    }
  }, [rep, salesReps]);

  const clearFacilityTransfer = () => {
    handleClearFacilityName();
    setStateFilter(null);
    setSelectedFacilities([]);
    setFacility([]);
    setTransferRight([]);
  };

  const handleDivisions = (div) => {
    clearFacilityTransfer();
    setDivisions(div);
    setDivisionNames(div.map((d) => d.label));
    setShowError(false);
  };

  const handleFacilityChecked = (value) => {
    setSelectedFacilities(value);
  };

  const handleFacilityFiltering = (value) => {
    setFacility(value);
    setShowError(false);
    if (isError) {
      reset();
    }
  };

  const handleStageRep = (value, { action }) => {
    setShowError(false);
    clearFacilityTransfer();
    setDivisions(null);
    setDivisionNames([]);
    if (action === 'select-option') {
      setRep(value);
      divisionRef.current.focus();
      if (isError) {
        reset();
      }
    } else if (action === 'clear') {
      setRep(null);
    }
  };

  useEffect(() => {
    if (isError) {
      setShowError(true);
    }
  }, [isError]);

  useEffect(() => {
    if (isOpen) {
      setShowError(false);
      reset();
      clearFacilityTransfer();
      setDivisions(null);
      setDivisionNames([]);
      setRep(null);
    }
  }, [isOpen]);

  const handleDisplayDisabledFacilities = (e) => {
    setDisplayDisabledFacilities(e.target.checked);
  };
  const disabledFacility = (item) => disabledFacilityIds.includes(item?.id);

  const handleStateChange = (value, { action }) => {
    if (action === 'select-option') {
      setStateFilter(value);
    } else if (action === 'clear') {
      setStateFilter(null);
    }
  };

  const handleFacilityNameChange = (e) => {
    debounceSearch(e, timerRef, transferLeft, COLUMNS, setFacilityNameFilter, setFacilityFilterStatus, setFacilitiesByName);
  };

  const handleClearFacilityName = () => {
    setFacilityNameFilter('');
    setFacilitiesByName([]);
  };

  return (
    <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose} isCentered size="xl" closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent maxW={'1100px'}>
        <ModalHeader>
          <HStack>
            <BsPersonPlus />
            <Text>Add Sales Rep</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <VStack spacing={4}>
            <FormControl id="sales_rep">
              <Select
                chakraStyles={chakraStyles}
                focusBorderColor="brand.700"
                aria-label="rep-select"
                ref={initialRef}
                options={enabledSalesReps}
                isSearchable
                isClearable
                value={rep}
                onChange={handleStageRep}
                components={{ SingleValue: SingleValueFirstLastName, Option: OptionFirstLastName, ClearIndicator }}
                getOptionLabel={(option) => `${option.first_name} ${option.last_name} ${option.email}`}
                getOptionValue={(option) => option.id}
                placeholder="Select a sales rep"
              />
            </FormControl>
            <FormControl id="division">
              <Select
                chakraStyles={chakraStyles}
                aria-label="division-select"
                focusBorderColor="brand.700"
                selectedOptionStyle={'check'}
                ref={divisionRef}
                isMulti={true}
                options={divisionsList}
                colorScheme="blue"
                isOptionDisabled={(option) => repDivisions.includes(option.label)}
                isSearchable
                isDisabled={!rep}
                value={divisions}
                isClearable
                onChange={handleDivisions}
                getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => option.value}
                placeholder="Select divisions"
              />
            </FormControl>
            <FacilityTransferList
              isLoading={isLoading}
              noResult={noResult}
              salesRep={rep}
              selectedFacilities={selectedFacilities}
              onFacilityChecked={handleFacilityChecked}
              onFilter={handleFacilityFiltering}
              left={transferLeft}
              setLeft={setTransferLeft}
              right={transferRight}
              setRight={setTransferRight}
              facilityFilterStatus={facilityFilterStatus}
              facilityInputValue={facilityNameFilter}
              onFacilityInputChange={handleFacilityNameChange}
              onFacilityInputClear={handleClearFacilityName}
              states={statesAttachedToContract}
              onStateChange={handleStateChange}
              stateValue={stateFilter}
              displayDisabledFacilities={displayDisabledFacilities}
              onDisplayDisabledFacilitiesChange={handleDisplayDisabledFacilities}
              disabledFacility={disabledFacility}
            />
          </VStack>
          {isError && showError && (
            <Box pt={6} id="error">
              <AlertComponent status="warning" title={`${rep?.first_name} ${rep?.last_name}`} description={errorMessage?.data?.detail} />
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={!rep} variant="cyan" mr={3} onClick={(e) => onSaveHandler(e, rep?.id, facility, divisionNames)}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

AddSalesRep.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSaveHandler: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  errorMessage: PropTypes.object,
  reset: PropTypes.func,
};

export default AddSalesRep;
