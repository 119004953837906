import { useGetDataDumpsQuery } from '@/app/services/nucleus';
import { Button, Heading, HStack } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import DataTableSearchAndFiltering from '../common/table/DataTableSearchAndFiltering';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDumpBlob,
  selectDataDumpDownloadError,
  selectDataDumpDownloadStatus,
  selectDataDumpSearchStatus,
  selectDataDumpSortBy,
  selectDataDumpSortDirection,
  setSortBy,
} from './dataDumpSlice';
import AlertComponent from '@/features/common/AlertComponent';
import { ERROR, LOADING } from '@/utils/constants';
import { useState } from 'react';

const DataDumpPage = () => {
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();
  const searchStatus = useSelector(selectDataDumpSearchStatus);
  const downloadError = useSelector(selectDataDumpDownloadError);
  const downloadStatus = useSelector(selectDataDumpDownloadStatus);
  const sortBy = useSelector(selectDataDumpSortBy);
  const sortDirection = useSelector(selectDataDumpSortDirection);
  const [dumpID, setDumpID] = useState(null);

  const { data: dataDumps, isLoading, isError, error: dataDumpsError } = useGetDataDumpsQuery();

  const handleFileDownload = async (e, id) => {
    setDumpID(id);
    const response = await dispatch(getDumpBlob(id));
    const blob = new Blob([response?.payload], { type: 'text/csv' });

    let anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = `data_dump_${id}.csv`;
    document.body.appendChild(anchor);
    anchor.click();
    anchor.parentNode.removeChild(anchor);
    // clean up
    URL.revokeObjectURL(anchor.href);
  };

  const handleDumpSortBy = (column) => {
    dispatch(setSortBy(column));
  };

  const dataDumpsColumns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: (info) => (info.getValue() ? info.getValue() : 'None'),
    }),
    columnHelper.accessor('', {
      header: 'Download File',
      cell: (info) => {
        const dumps = info.row.original;
        return (
          <HStack>
            <Button
              isLoading={downloadStatus === 'loading' && dumpID === dumps.id}
              size="sm"
              variant="outline"
              onClick={(e) => handleFileDownload(e, dumps.id)}
            >
              Download
            </Button>
          </HStack>
        );
      },
    }),
  ];

  return (
    <>
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        Data Dumps
      </Heading>
      {downloadError && <AlertComponent status="error" title="Error" description="Error downloading file." />}
      <DataTableSearchAndFiltering
        title="Data Dumps"
        originalData={dataDumps}
        searchStatus={searchStatus}
        sortBy={sortBy}
        isSearchLoading={searchStatus === LOADING}
        isSearchError={searchStatus === ERROR}
        isDataLoading={isLoading}
        isDataError={isError || dataDumpsError}
        dataErrorMsg={dataDumpsError?.data?.detail}
        dataErrorTitle={'Error fetching data dumps'}
        dataColumns={dataDumpsColumns}
        handleSortBy={handleDumpSortBy}
        searchColumns={['name']}
        sortDirection={sortDirection}
      ></DataTableSearchAndFiltering>
    </>
  );
};

export default DataDumpPage;
