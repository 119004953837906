import PropTypes from 'prop-types';
import { Box, CloseButton, Flex, Icon, Link, useColorModeValue } from '@chakra-ui/react';
import { FiFileText, FiLayout, FiSearch, FiTag } from 'react-icons/fi';
import { BiBuildings, BiCloudUpload, BiCalculator, BiUserCircle, BiErrorAlt, BiData } from 'react-icons/bi';
import { MdOutlineFolderSpecial, MdPersonSearch } from 'react-icons/md';
import { NavLink as RouterLink } from 'react-router-dom';
import { TbCertificate, TbListSearch } from 'react-icons/tb';
import triageLogo from '/triage.svg';
import { useSelector } from 'react-redux';
import { selectAuthUserSortBy, selectUserAdmin, selectUserRoles, selectUserSuperadmin, selectUserUsername } from '../user/userSlice';
import { selectContractSortBy, selectContractSortDirection } from '../contract/contractSlice';
import { selectModalitySortBy, selectModalitySortDirection } from '../modality/modalitySlice';

const viteHost = import.meta.env.VITE_HOST;

const SidebarContent = ({ onClose, isMobile }) => {
  const white = useColorModeValue('gray.100', 'gray.900');
  const gray = useColorModeValue('gray.300', 'gray.700');
  const roles = useSelector(selectUserRoles);
  const admin = useSelector(selectUserAdmin);
  const superadmin = useSelector(selectUserSuperadmin);
  const name = useSelector(selectUserUsername);
  const sortBy = useSelector(selectContractSortBy);
  const sortDirection = useSelector(selectContractSortDirection);
  const authUserSortBy = useSelector(selectAuthUserSortBy);
  const authUserSortDirection = useSelector(selectContractSortDirection);
  const modalitySortBy = useSelector(selectModalitySortBy);
  const modalitySortDirection = useSelector(selectModalitySortDirection);

  const linkItems = [
    { name: 'Pages', heading: true, roles: ['support'], border: false },
    {
      name: 'Contracts',
      icon: FiFileText,
      href: `/contracts?sortBy=${sortBy}&sortDirection=${sortDirection}`,
      target: '_self',
      roles: ['contracts'],
    },
    { name: 'Job Loader', icon: BiCloudUpload, href: '/job_loader', target: '_self', roles: ['jobs'] },
    { name: 'Margin Rules', icon: BiCalculator, admin: true, href: '/margin_rules', target: '_self' },
    { name: 'Facility Mapping', icon: BiBuildings, roles: ['jobs'], href: '/facility_mapping', target: '_self' },
    { name: 'Specialty Mapping', icon: MdOutlineFolderSpecial, roles: ['jobs'], href: '/specialty_mapping', target: '_self' },
    { name: 'Entity Lookup', icon: MdPersonSearch, href: '/entity_lookup', superadmin: false, roles: ['support'] },
    { name: 'Certifications', icon: TbCertificate, href: '/certifications', target: '_self', roles: ['support'] },
    { name: 'Search Data Dumps', icon: BiData, href: '/data_dumps', target: '_self' },
    { name: 'Administration', heading: true, admin: true, border: true },
    { name: 'Feature Management', icon: FiLayout, href: '/feature_management', superadmin: true },
    {
      name: 'User Management',
      icon: BiUserCircle,
      href: `/user_management?sortBy=${authUserSortBy}&sortDirection=${authUserSortDirection}`,
      target: '_self',
      admin: true,
    },
    {
      name: 'Modalities',
      icon: FiTag,
      href: `/modalities?sortBy=${modalitySortBy}&sortDirection=${modalitySortDirection}`,
      target: '_self',
      admin: true,
    },
    { name: 'Troubleshooting', heading: true, roles: ['support'], border: true },
    { name: 'Search Tracers', icon: FiSearch, href: `${viteHost}/monitoring/redis/tracers?search=&offset=0&limit=100`, target: '_blank' },
    { name: 'Get Tracer Errors', icon: BiErrorAlt, href: `${viteHost}/monitoring/redis/tracers/errors`, target: '_blank' },
    { name: 'Monitor Consumer Groups', icon: TbListSearch, href: `${viteHost}/monitoring/redis/consumers/groups`, target: '_blank' },
  ];

  return (
    <Box
      bg={white}
      borderRightWidth="1px"
      borderRightColor={gray}
      w={{ base: 'full', md: 64 }}
      pos="fixed"
      h="full"
      display={isMobile ? { base: 'block', md: 'none' } : { base: 'none', md: 'block' }}
      zIndex={2}
      overflowY="scroll"
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Box>
          <Link as={RouterLink} to="/">
            <img src={triageLogo} className="logo" alt="Triage logo" width={200} />
          </Link>
        </Box>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {name !== '' &&
        linkItems.map((link) => {
          if ((link?.admin && !admin) || (link?.superadmin && !superadmin)) {
            return null;
          }
          if (link?.heading) {
            return (
              <Box
                key={link.name}
                pt={3}
                px={6}
                color="brand.900"
                fontSize="md"
                my="2"
                fontWeight="bold"
                borderTopColor={gray}
                borderTopWidth={link.border ? '1px' : '0'}
              >
                {link.name}
              </Box>
            );
          }
          // if linkItems contains roles, check if user has any of the roles or is admin
          if ((link.roles && link.roles.length > 0 && link.roles.some((r) => roles?.includes(r))) || admin || !link.roles) {
            return (
              <NavItem key={link.name} icon={link.icon} href={link.href} target={link.target}>
                {link.name}
              </NavItem>
            );
          }
        })}
    </Box>
  );
};

SidebarContent.propTypes = {
  onClose: PropTypes.func,
  isMobile: PropTypes.bool,
};

const NavItem = ({ icon, href, target, children }) => {
  return (
    <Link
      as={RouterLink}
      to={href || '#'}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      target={target || '_self'}
      _activeLink={{ color: 'brand.700' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'brand.700',
          color: 'white',
        }}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

NavItem.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  href: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node,
};

export default SidebarContent;
