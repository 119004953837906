import { compareValues, isArrayValue, isRealValue } from '@/utils/helpers';
import {
  setContractClient,
  setContractDivisions,
  setContractFacilities,
  setContractRateCardData,
  setContractRateCards,
  setContractSalesRepAssociations,
  setContractStatus,
} from './contractSlice';
import { ARCHIVED } from '@/utils/constants.js';
import { addTruncatedProperties } from '@/app/services/helper.js';

export const clearContractData = () => (dispatch) => {
  dispatch(setContractStatus(''));
  dispatch(setContractDivisions([]));
  dispatch(setContractRateCardData([]));
  dispatch(setContractRateCards([]));
  dispatch(setContractFacilities([]));
  dispatch(setContractClient(null));
  dispatch(setContractSalesRepAssociations([]));
};

export const assignContractData = (contract) => (dispatch) => {
  if (contract?.id !== '') {
    const status = contract?.is_deleted ? ARCHIVED : contract?.status;
    if (isRealValue(status)) {
      dispatch(setContractStatus(status));
    } else {
      dispatch(setContractStatus(''));
    }
    const divisions = contract?.divisions;
    if (Array.isArray(divisions) && divisions[0] !== '') {
      dispatch(setContractDivisions(divisions));
    } else {
      dispatch(setContractDivisions([]));
    }
    const rateCards = contract?.rate_cards;
    if (isArrayValue(rateCards)) {
      const cards = rateCards.filter((card) => !card.is_deleted);
      dispatch(setContractRateCardData(cards));
      if (cards.length === 0) {
        dispatch(setContractRateCards([]));
      }
    } else {
      dispatch(setContractRateCardData([]));
      dispatch(setContractRateCards([]));
    }
    const facilities = contract?.facilities;
    if (isArrayValue(facilities)) {
      dispatch(setContractFacilities(facilities));
    } else {
      dispatch(setContractFacilities([]));
    }
    const client = contract?.client;
    if (typeof client === 'object' && !Array.isArray(client) && client !== null) {
      dispatch(setContractClient(client));
    } else {
      dispatch(setContractClient(null));
    }
    dispatch(setContractSalesRepAssociations(contract?.sales_rep_associations));
  }
};

// Add comma delimited list of short_names to each rate card
export const assignRateCardShortNames = (rateCardData) => (dispatch, getState) => {
  const state = getState();
  const modalities = state.modality.modalities;
  const rateCards = rateCardData.map((card) => {
    const ids = card?.modality_ids || [];
    const linkedModalities = modalities.filter((modal) => ids.includes(modal.id) && modal.short_name);
    let shortNames = [];
    for (const item of linkedModalities) {
      shortNames.push(item?.short_name);
    }
    const deDupedShortNames = [...new Set(shortNames)];
    return { ...card, shortNames: deDupedShortNames.join(','), short_names: deDupedShortNames };
  });
  const trunc = addTruncatedProperties('short_names', rateCards, 20);
  const data = trunc.sort(compareValues('name', 'asc'));
  dispatch(setContractRateCards(data));
};

// INFO: list of US States attached to facilities assigned to the contract
// filter out any states belonging to disabled facilities
export const getAllStatesAttachedToContract = (facilities, disabledFacilityIds, displayDisabledFacilities) => {
  const enabledFacilities =
    !displayDisabledFacilities && disabledFacilityIds ? facilities.filter((f) => !disabledFacilityIds.includes(f.id)) : facilities;
  const states = enabledFacilities
    .map((f) => ({ label: f.state, value: f.id }))
    .filter((value, index, self) => self.findIndex((t) => t.label === value.label) === index)
    .filter((item) => item?.label);
  return states.sort((a, b) => a.label.localeCompare(b.label));
};

// returns an array of facility ids that should be disabled for all reps in division
export const getAllDisabledFacilitiesByDivision = (salesReps, facilities, divisions, currentRep) => {
  let filteredFacilities = [];
  // flatten divisions into array of division labels
  const divisionLabels = isArrayValue(divisions) ? divisions.map((div) => div.label) : null;

  // get all reps in the selected divisions
  if (isArrayValue(salesReps) && divisionLabels) {
    const inDivision = salesReps.filter((rep) => rep.divisions.some((div) => divisionLabels.includes(div)));

    // Filter out the current rep's facilities
    const filteredReps = inDivision.filter((rep) => rep.id !== currentRep?.id);

    // Get facility_ids from reps in the filtered list
    const justFacilityIds = filteredReps.reduce((prev, cur) => {
      const idArray = cur.facility_ids;
      return [...prev, ...idArray];
    }, []);

    // Remove any current rep's facility IDs from this list
    const uniqueIds = [...new Set(justFacilityIds)];
    const filteredUniqueIds = uniqueIds.filter((id) => !currentRep?.facility_ids.includes(id));
    // Return a list of facilities in our divisions excluding current rep's facilities
    if (filteredReps && filteredReps.length > 0) {
      filteredFacilities = facilities.filter((fac) => filteredUniqueIds.includes(fac.id));
    }
  }

  return filteredFacilities.reduce((prev, cur) => [...prev, cur.id], []);
};
