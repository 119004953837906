import React, { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  VStack,
  HStack,
  Box,
} from '@chakra-ui/react';
import { BsPersonGear } from 'react-icons/bs';
import { Select } from 'chakra-react-select';
import { useSelector } from 'react-redux';
import { selectContractDivisionsList, selectContractFacilities } from '../contract/contractSlice';
import { getAllDisabledFacilitiesByDivision, getAllStatesAttachedToContract } from '../contract/contractActions';
import { useGetContractRepsQuery } from '@/app/services/nucleus';
import { isArrayValue } from '@/utils/helpers.js';
import { chakraStyles } from '@/features/common/select/styles.js';
import FacilityTransferList from '@/features/salesRep/FacilityTransferList.jsx';
import { debounceSearch } from '@/features/contract/contractHelper.js';
import { useTransferList } from '@/hooks/useTransferList.js';
import AlertComponent from '@/features/common/AlertComponent.jsx';

const COLUMNS = ['name', 'address'];
const EditSalesRep = ({ id, repDivisions, rep, isOpen, onClose, isLoading, isError, reset, onUpdate, errorMessage }) => {
  const editDivisionRef = useRef(null);
  const finalRef = useRef(null);
  const timerRef = useRef(null);

  const { data: salesReps } = useGetContractRepsQuery({ id });

  const editDividionList = useSelector(selectContractDivisionsList);

  const [divisions, setDivisions] = useState(null);
  const [divisionNames, setDivisionNames] = useState([]);
  const [transferRight, setTransferRight] = useState([]);
  const [transferLeft, setTransferLeft] = useState([]);
  const [stateFilter, setStateFilter] = useState(null);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [displayDisabledFacilities, setDisplayDisabledFacilities] = useState(false);
  const [facility, setFacility] = useState([]);
  const [facilityFilterStatus, setFacilityFilterStatus] = useState('');
  const [facilityNameFilter, setFacilityNameFilter] = useState('');
  const [facilitiesByName, setFacilitiesByName] = useState([]);
  const [noResult, setNoResult] = useState(false);

  const facilities = useSelector(selectContractFacilities);

  useEffect(() => {
    if (repDivisions) {
      setDivisions(repDivisions.map((d) => ({ label: d, value: d })));
      setDivisionNames(repDivisions);
    }
  }, [repDivisions, isOpen]);

  const repFacilities = useMemo(() => {
    if (isArrayValue(salesReps) && rep) {
      return salesReps.find((r) => r.id === rep.id)?.facilities;
    } else {
      return [];
    }
  }, [salesReps, rep]);

  // memoize a list of divisions that we can use to disable items in dropdown
  const allRepDivisions = useMemo(() => {
    if (isArrayValue(salesReps) && rep && repDivisions) {
      // a rep can be attached multiple times. get all of them
      const allReps = salesReps.filter((r) => r.rep_id === rep.rep_id);
      // flatten a list of all divisions associated with rep.rep_id
      const justDivs = allReps.reduce((prev, cur) => [...prev, ...cur.divisions], []);
      // filter out the divisions for this specific rep.id
      return justDivs.filter((d) => !repDivisions.includes(d));
    } else {
      return [];
    }
  }, [salesReps, rep, repDivisions]);

  const disabledFacilityIds = useMemo(
    () => getAllDisabledFacilitiesByDivision(salesReps, facilities, divisions, rep),
    [divisions, facilities, salesReps, rep],
  );

  const statesAttachedToContract = useMemo(
    () => getAllStatesAttachedToContract(facilities, disabledFacilityIds, displayDisabledFacilities),
    [facilities, disabledFacilityIds, displayDisabledFacilities],
  );

  useEffect(() => {
    if (repFacilities) {
      setTransferRight(repFacilities);
    }
  }, [repFacilities, isOpen]);

  useEffect(() => {
    if (isOpen) {
      clearFacilityTransfer();
      reset();
    }
  }, [isOpen]);

  useTransferList(
    facility,
    setNoResult,
    facilityNameFilter,
    displayDisabledFacilities,
    disabledFacilityIds,
    selectedFacilities,
    setSelectedFacilities,
    transferRight,
    stateFilter,
    facilitiesByName,
    setTransferLeft,
  );

  const editFacilityChecked = (value) => {
    setSelectedFacilities(value);
  };

  const clearFacilityTransfer = () => {
    handleClearFacilityName();
    setStateFilter(null);
    setSelectedFacilities([]);
  };

  const handleEditDivisions = (div) => {
    clearFacilityTransfer();
    setDivisions(div);
    setDivisionNames(div.map((d) => d.label));
    if (isError) {
      reset();
    }
  };

  const editFacilityFiltering = (value) => {
    setFacility(value);
    if (isError) {
      reset();
    }
  };

  const handleFacilityNameChange = (e) => {
    debounceSearch(e, timerRef, transferLeft, COLUMNS, setFacilityNameFilter, setFacilityFilterStatus, setFacilitiesByName);
  };

  const handleClearFacilityName = () => {
    setFacilityNameFilter('');
    setFacilitiesByName([]);
  };

  const handleStateChange = (value, { action }) => {
    if (action === 'select-option') {
      setStateFilter(value);
    } else if (action === 'clear') {
      setStateFilter(null);
    }
  };

  const handleDisplayDisabledFacilities = (e) => {
    setDisplayDisabledFacilities(e.target.checked);
  };

  const disabledFacility = (item) => disabledFacilityIds.includes(item?.id);

  return (
    <Modal
      initialFocusRef={editDivisionRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent maxW={'1100px'}>
        <ModalHeader>
          <HStack>
            <BsPersonGear style={{ height: '25px', width: '25px' }} />
            <Text>Editing</Text>
            <Text>{rep.name}</Text>
          </HStack>
        </ModalHeader>
        <ModalCloseButton isDisabled={false} />
        <ModalBody pb={6}>
          <VStack spacing={4}>
            <FormControl id="division">
              <Select
                chakraStyles={chakraStyles}
                aria-label="edit-division-select"
                focusBorderColor="brand.700"
                selectedOptionStyle={'check'}
                ref={editDivisionRef}
                isMulti={true}
                options={editDividionList}
                colorScheme="blue"
                isOptionDisabled={(option) => allRepDivisions.includes(option.label)}
                isSearchable
                isDisabled={!rep}
                value={divisions}
                isClearable
                onChange={handleEditDivisions}
                placeholder="Edit divisions"
              />
            </FormControl>
            <FacilityTransferList
              isLoading={isLoading}
              noResult={noResult}
              salesRep={rep}
              selectedFacilities={selectedFacilities}
              onFacilityChecked={editFacilityChecked}
              onFilter={editFacilityFiltering}
              left={transferLeft}
              setLeft={setTransferLeft}
              right={transferRight}
              setRight={setTransferRight}
              facilityFilterStatus={facilityFilterStatus}
              facilityInputValue={facilityNameFilter}
              onFacilityInputChange={handleFacilityNameChange}
              onFacilityInputClear={handleClearFacilityName}
              states={statesAttachedToContract}
              onStateChange={handleStateChange}
              stateValue={stateFilter}
              displayDisabledFacilities={displayDisabledFacilities}
              onDisplayDisabledFacilitiesChange={handleDisplayDisabledFacilities}
              disabledFacility={disabledFacility}
            />
          </VStack>
          {isError && (
            <Box pt={6} id="error">
              <AlertComponent status="warning" title={rep?.name} description={errorMessage?.data?.detail} />
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={!rep} variant="cyan" mr={3} onClick={(e) => onUpdate(e, rep?.rep_id, rep?.id, facility, divisionNames)}>
            Save
          </Button>
          <Button onClick={onClose} isDisabled={false}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

EditSalesRep.propTypes = {
  id: PropTypes.string,
  repDivisions: PropTypes.array,
  rep: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  onUpdate: PropTypes.func,
  reset: PropTypes.func,
  errorMessage: PropTypes.object,
};

export default EditSalesRep;
