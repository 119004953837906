export const statusConstants = Object.freeze({
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  TOKEN_RECEIVED: 'TOKEN RECEIVED',
  LOGGED_IN: 'LOGGED IN',
  LOGGED_OUT: 'LOGGED OUT',
  LOGIN_ERROR: 'LOGIN ERROR',
  ERROR: 'ERROR',
  SEARCHING: 'SEARCHING',
  NO_RESULTS: 'NO RESULTS',
});
export const { ERROR, LOADED, LOGGED_IN, LOGGED_OUT, LOGIN_ERROR, LOADING, TOKEN_RECEIVED, SEARCHING, NO_RESULTS } = statusConstants;

const tags = Object.freeze({
  CONTRACT: 'Contract',
  ALL_CONTRACTS: 'AllContracts',
  REPS: 'Reps',
  MARGIN_RULES: 'MarginRules',
  FACILITY_MAPPING: 'FacilityMapping',
  SPECIALTY_MAPPING: 'SpecialtyMapping',
  FACILITY: 'Facility',
  FEATURES: 'Features',
  MSP: 'MSP',
  COMPANY: 'Company',
  HEALTH_SYSTEM: 'HealthSystem',
  RATE: 'Rate',
  USER: 'User',
  MODALITY: 'Modality',
  SPECIALTIES: 'Specialties',
  PROFESSIONS: 'Professions',
  AUTH_USER: 'AuthUser',
  CERTIFICATIONS: 'Certifications',
  DATA_DUMPS: 'DataDumps',
});
export const {
  CONTRACT,
  ALL_CONTRACTS,
  MARGIN_RULES,
  REPS,
  FACILITY_MAPPING,
  SPECIALTY_MAPPING,
  FACILITY,
  FEATURES,
  MSP,
  COMPANY,
  HEALTH_SYSTEM,
  RATE,
  USER,
  MODALITY,
  SPECIALTIES,
  PROFESSIONS,
  AUTH_USER,
  CERTIFICATIONS,
  DATA_DUMPS,
} = tags;

export const days = Object.freeze({
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
});
export const { MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY } = days;

export const status = Object.freeze({
  NEW: 'New',
  DRAFT: 'Draft',
  PUBLISHED: 'Published',
  ARCHIVED: 'Archived',
});
export const { NEW, DRAFT, PUBLISHED, ARCHIVED } = status;

export const tabs = Object.freeze({
  DETAILS: 'details',
  FACILITIES: 'facilities',
  RATE_CARDS: 'rate_cards',
  SALES_REP_ASSIGNMENT: 'sales_rep_assignment',
});
export const { DETAILS, FACILITIES, RATE_CARDS, SALES_REP_ASSIGNMENT } = tabs;

export const tabsHelper = (index) => {
  switch (index) {
    case 0:
      return DETAILS;
    case 1:
      return FACILITIES;
    case 2:
      return RATE_CARDS;
    case 3:
      return SALES_REP_ASSIGNMENT;
    default:
      break;
  }
};

export const role = Object.freeze({
  SALES: 'sales_rep',
});
export const { SALES } = role;

export const DATA_LIMIT = 1000;
export const MAX_LIST_LENGTH = 35;
export const MAX_FILE_SIZE = 500000;

export const features = Object.freeze({
  CAN_EDIT_TOP_LEVEL_CONTRACT: 'CAN_EDIT_TOP_LEVEL_CONTRACT',
});
export const { CAN_EDIT_TOP_LEVEL_CONTRACT } = features;

export const scrollbar = {
  '&::-webkit-scrollbar': {
    height: '5px',
  },
  '&::-webkit-scrollbar-track': {
    height: '6px',
    background: '#f7f7f7',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#cccccc',
    borderRadius: '24px',
  },
};

export const pageOptions = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];
