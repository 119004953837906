import React, { useMemo, useState } from 'react';
import { DndContext, KeyboardSensor, MeasuringStrategy, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DragHandle, SortableItem } from './SortableItem';
import SortableOverlay from './SortableOverlay';
import { VStack } from '@chakra-ui/react';
import { Virtuoso } from 'react-virtuoso';

export const SortableList = ({ items, onChange, renderItem, isReadOnly }) => {
  const [sortItem, setSortItem] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  const activeItem = useMemo(() => {
    if (items) {
      return items.find((item) => item.id === sortItem?.id);
    }
    return {};
  }, [sortItem, items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const measuringConfig = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };

  // TODO: need to add border-bottom to elements or darken the border

  return (
    <DndContext
      sensors={sensors}
      measuring={measuringConfig}
      onDragStart={({ active }) => {
        setSortItem(active);
        setIsDragging(true);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);
          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setSortItem(null);
        setIsDragging(false);
      }}
      onDragCancel={() => {
        setSortItem(null);
      }}
    >
      <SortableContext items={items || []} strategy={verticalListSortingStrategy}>
        <VStack aria-label="Sortable list" spacing={0} p={0} marginLeft={'-25px'} marginRight={'-25px'} role="application">
          <Virtuoso
            style={{ height: 'calc(100vh - 320px)', width: '100%' }}
            data={items}
            totalCount={items ? items.length : 0}
            itemContent={(index, item) => {
              const isFirst = index === 0;
              const isLast = items ? index === items.length - 1 : false;
              return (
                <SortableList.Item key={item.id} id={item.id} isDisabled={isReadOnly}>
                  {renderItem(item, isFirst, isLast, item.open)}
                </SortableList.Item>
              );
            }}
          />
        </VStack>
      </SortableContext>
      <SortableOverlay>{isDragging ? renderItem(activeItem, false, false, activeItem?.open) : null}</SortableOverlay>
    </DndContext>
  );
};

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
