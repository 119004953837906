import { Box, Flex } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const RuleHeader = ({ children }) => {
  return (
    <Box
      _hover={{
        bg: 'gray.50',
      }}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        padding: '0px',
      }}
    >
      <Flex alignItems="center">{children}</Flex>
    </Box>
  );
};
RuleHeader.propTypes = {
  children: PropTypes.node,
};
export default RuleHeader;
