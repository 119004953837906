import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

const RuleNumber = ({ children }) => {
  return (
    <Box
      display="inline-block"
      mr={2}
      px={2}
      py={1}
      borderWidth="1px"
      borderColor="gray.200"
      bgColor="gray.50"
      color="gray.600"
      borderRadius="4px"
      fontSize="0.75rem"
      minWidth="30px"
      textAlign="center"
    >
      {children}
    </Box>
  );
};
RuleNumber.propTypes = {
  children: PropTypes.node,
};
export default RuleNumber;
