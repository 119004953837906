import { createSlice } from '@reduxjs/toolkit';
import { nucleusApi } from '@/app/services/nucleus';

const initialState = {
  division: '',
  rules: [],
  ruleTemplate: {
    name: {
      value: null,
      label: '',
      valid: true,
      error: '',
    },
    open: true,
    expires_on: {
      value: null,
      label: 'Expires On',
      valid: true,
      error: '',
    },
    margin: {
      value: 0,
      label: 'Margin Goal',
      valid: true,
      error: '',
    },
    minimum_margin: {
      value: 0,
      label: 'Min Margin Goal',
      valid: true,
      error: '',
    },
    min_pay_regular: {
      value: 0.0,
      label: 'Min Regular Pay',
      valid: true,
      error: '',
    },
    pay_travel: {
      value: 0.0,
      label: 'Max Travel Burden',
      valid: true,
      error: '',
    },
    should_calculate_pay: {
      value: true,
      label: 'Calculate Pay',
      valid: true,
      error: '',
    },
  },
};

export const marginSlice = createSlice({
  name: 'margin',
  initialState,
  reducers: {
    setMarginRules: (state, { payload }) => {
      state.rules = payload;
    },
    setMarginDivision: (state, { payload }) => {
      state.division = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(nucleusApi.endpoints.getMarginRules.matchFulfilled, (state, { payload }) => {
      if (payload?.rules && payload.rules.length > 0) {
        state.division = payload?.division;
        state.rules = payload?.rules;
      }
    });
  },
});

export const { setMarginRules, setMarginDivision } = marginSlice.actions;

export const selectMarginRules = (state) => state.margin.rules;
export const selectMarginRuleTemplate = (state) => state.margin.ruleTemplate;

export default marginSlice.reducer;
